import React, { useRef, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import { Col, Row } from "react-bootstrap";
import { jsonEditorOptions } from "../../utils/jsonEditorOption";

import InputField, {
  RadioField,
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";

import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { CubeSpinner } from "../../Components/Spinners/Spinners";
import styles from "./actions.module.css";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import {
  useAddActionMutation,
  useGetActionsByIdQuery,
  useUpdateActionMutation,
} from "../../services/actionsService";
import { useSelector } from "react-redux";
import { isJSON, getStandardErrMsg } from "../../utils/utils";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import {
  NewStepHeader,
  StepsHeader,
} from "../../Components/StepsHeader/StepsHeader";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";
import useOperationFeedback from "../../utils/useOperationFeedback";
import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import BackButton from "../../Components/BackButton/BackButton";
import FullscreenEditor from "../../Components/FullScreenEditor/FullScreenEditor.jsx";
const options = [
  { value: "", label: "Select" },
  { value: "prod", label: "Prod" },
  { value: "non-prod", label: "Non-Prod" },
  { value: "test", label: "Test" },
];

const AddNewAction = () => {
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const { action_id } = useParams();

  const skip = action_id ? false : true;

  const { data, error, isFetching } = useGetActionsByIdQuery(action_id, {
    skip,
  });

  const editorRef = useRef(null);

  const [activeStep, setActiveStep] = useState(1);
  const [action, setAction] = useState({
    title: "",
    filename: "",
    desc: "",
    stage: "",
    share: false,
  });

  const [fieldError, setFieldError] = useState({
    title: "",
    filename: "",
    stage: "",
  });

  const [actionConfig, setActionConfig] =
    useState(`# Function to check if a number is even or odd
def check_even_odd(number):
    if number % 2 == 0:
        return "Even"
    else:
        return "Odd"

# Example usage
number = int(input("Enter a number: "))
result = check_even_odd(number)
print(f"The number {number} is {result}.")`);

  useEffect(() => {
    if (action_id && data) {
      setAction({
        title: data.title,
        filename: data.file_name,
        stage: data.actions_version[0].stage,
        share: data.share,
        desc: data.description,
      });
      // console.log(
      // "🚀 ~ useEffect ~ isJSON(data.actions_version[0].config):",
      // isJSON(data.actions_version[0].config)
      // );

      if (isJSON(data.actions_version[0].config)) {
        const config = JSON.parse(data.actions_version[0].config);
        // console.log("🚀 ~ useEffect ~ config:", config);
        setActionConfig(config.content);
      } else {
        setActionConfig(data.actions_version[0].config.content);
      }
    }
  }, [data, action_id]);

  const [addAction, addActionInfo] = useAddActionMutation();
  const [updateAction, updateActionInfo] = useUpdateActionMutation();

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  }

  useOperationFeedback(
    addActionInfo.isSuccess,
    addActionInfo.isError,
    "/actions"
  );
  useOperationFeedback(
    updateActionInfo.isSuccess,
    updateActionInfo.isError,
    "/actions"
  );

  const handleValidation = () => {
    let valid = true;
    let newErrors = {};
    for (const field in fieldError) {
      newErrors[field] = "";
    }
    console.log("🚀 ~ handleValidation ~ newErrors:", newErrors, fieldError);

    for (const field in fieldError) {
      if (!action[field]) {
        valid = false;
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    }

    // If there are errors, update the state with the errors
    if (!valid) {
      setFieldError(newErrors);
    }

    return valid;
  };

  const handleNext = () => {
    if (handleValidation()) {
      setActiveStep(activeStep + 1);
      setFieldError({
        title: "",
        filename: "",
        stage: "",
      });
    }
  };

  const handlePrev = () => {
    setActiveStep(1);
  };

  function showValue() {
    const body = {
      space_id: action_id ? data.space_id : currentSpace,
      action_id: action_id ? action_id : null,
      action_version_id: action_id
        ? data.actions_version[0].action_version_id
        : null,
      title: action.title,
      description: action.desc,
      file_name: action.filename,
      share: action.share,
      parent_id: 0,
      platform_default: true,
      version: "1.0",
      stage: action.stage,
      config: { content: editorRef.current.getValue() },
      content: "",
    };
    // console.log("🚀 ~ showValue ~ body:", body);

    action_id
      ? updateAction({ body: body, action_id: action_id })
      : addAction(body);
  }

  return (
    <ContentWrapper>
      <Banner>{action_id ? "Edit" : "Add"} Action</Banner>

      <Row className="mt-5">
        <BackButton />

        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <div className="bs-stepper wizard-vertical vertical mt-2">
            <StepsHeader>
              <NewStepHeader
                activeStep={activeStep}
                stepNumber={1}
                stepTitle={"New Action"}
                stepSubtitle={"Enter action details"}
                handleClick={handlePrev}
              />
              <div className="line" />

              <NewStepHeader
                activeStep={activeStep}
                stepNumber={2}
                stepTitle={"Code Editor"}
                stepSubtitle={"Write your action"}
                handleClick={handleNext}
              />
            </StepsHeader>

            <div className="bs-stepper-content">
              {activeStep === 1 && (
                <Step1
                  setActiveStep={setActiveStep}
                  action={action}
                  setAction={setAction}
                  data={data}
                  error={fieldError}
                  handleNext={handleNext}
                />
              )}
              {activeStep === 2 && (
                <Step2
                  showValue={showValue}
                  handleEditorDidMount={handleEditorDidMount}
                  addActionInfo={addActionInfo}
                  updateActionInfo={updateActionInfo}
                  data={actionConfig}
                  handlePrev={handlePrev}
                  setActionConfig={setActionConfig}
                />
              )}
            </div>
          </div>
        )}
      </Row>
    </ContentWrapper>
  );
};
const Step1 = ({ action, setAction, error, handleNext }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "share") {
      setAction((prevSpace) => ({
        ...prevSpace,
        [name]: value,
      }));
    } else {
      setAction((prevSpace) => ({
        ...prevSpace,
        [name]: !action.share,
      }));
    }
  };

  return (
    <>
      <CurrentStepHeading title="Action" titleSummary="Enter action details" />

      <FieldWrapper errorMsg={error.title}>
        <InputField
          type="title"
          value={action.title}
          placeholder="Title"
          label="Title"
          name="title"
          onChange={handleChange}
        />
      </FieldWrapper>
      <FieldWrapper errorMsg={error.filename}>
        <InputField
          type="text"
          value={action.filename}
          placeholder="File"
          label="Filename"
          name="filename"
          onChange={handleChange}
        />
      </FieldWrapper>

      <Row className="mb-3 ">
        <Col>
          <div className="fv-plugins-icon-container">
            <SelectInput
              options={options}
              value={action.stage}
              onChange={handleChange}
              label="Select Stage"
              name={"stage"}
            />
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              {error.stage}
            </div>
          </div>
        </Col>
        <Col>
          <div className=" fv-plugins-icon-container">
            <RadioField
              type="checkbox"
              value={action.share}
              placeholder=""
              label="Share With Others"
              name="share"
              onChange={handleChange}
              mainLabel="Share"
            />
          </div>
        </Col>
      </Row>

      <FieldWrapper errorMsg={error.desc}>
        <Textarea
          value={action.desc}
          placeholder="Desc..."
          label="Description"
          name="desc"
          onChange={handleChange}
        />
      </FieldWrapper>

      <PrimaryButton handleClick={handleNext}>
        Next <i className="ti ti-arrow-right" />
      </PrimaryButton>
    </>
  );
};

const Step2 = ({
  showValue,
  handleEditorDidMount,
  addActionInfo,
  updateActionInfo,
  data,
  handlePrev,
  setActionConfig,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };
  return (
    <>
      <CurrentStepHeading
        title="Code Editor"
        titleSummary="Write your python code"
      />
      {/* <button onClick={toggleFullscreen}>
        {isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
      </button>
      <div
      className={`editor-container ${isFullscreen ? "editor-fullscreen" : ""}`}>

      </div> */}

      <FullscreenEditor
        height="50vh"
        defaultLanguage="python"
        defaultValue={data}
        theme="vs-dark"
        options={jsonEditorOptions}
        onMount={handleEditorDidMount}
        onChange={setActionConfig}
      />
      <div className="col-12 d-flex justify-content-between mt-3">
        <SecondaryButton handleClick={handlePrev}>
          {" "}
          <i className="ti ti-arrow-left me-sm-1 me-0" /> Previous
        </SecondaryButton>

        {addActionInfo.isLoading || updateActionInfo.isLoading ? (
          <LoadingButton />
        ) : (
          <PrimaryButton handleClick={showValue} variant="submit">
            Submit{" "}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default AddNewAction;
