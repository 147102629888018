import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Card, Modal, Button, Spinner, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useGetDeploymentsDetailQuery,
  useGetPodLogsQuery,
} from "../../services/deploymentsService";
import Banner from "../../Components/Banner/Banner";
import { ErrorAlert, PrimayAlert } from "../../Components/Alerts/Alerts";
import styles from "./deployments.module.css";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

const Deployments = () => {
  const { id } = useParams();
  const logContainerRef = useRef(null);
  const { data, error, isFetching } = useGetDeploymentsDetailQuery(id, {
    skip: !id,
  });
  const [selectedPod, setSelectedPod] = useState(null);

  const {
    data: logsData,
    isFetching: isLoadingLogs,
    error: logsError,
  } = useGetPodLogsQuery(
    selectedPod
      ? { pod_name: selectedPod.pod_name, pod_namespace: selectedPod.namespace }
      : skipToken
  );

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Banner>Your Deployments</Banner>
        <Row className={`mt-4 ${styles.row}`}>
          {isFetching ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : error ? (
            <ErrorAlert>Error fetching deployments</ErrorAlert>
          ) : !data || data.length === 0 ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            data.map((pod, index) => (
              <Col key={index} md={4} className="mb-4">
                <Card className="shadow-sm">
                  <Card.Body>
                    <Card.Title className={styles.cardHeading}>
                      {pod.pod_name}
                    </Card.Title>
                    <Card.Text>
                      <div className="d-flex justify-content-between">
                        <Badge
                          bg={pod.status === "Running" ? "success" : "danger"}
                        >
                          {pod.status || "Unknown"}
                        </Badge>
                        <Badge bg="info">{pod.event_type || "ADDED"}</Badge>
                      </div>
                      <div className="mt-2">
                        <strong>Namespace:</strong> {pod.namespace}
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <span title={String(pod["cpu_usage (cores)"])}>
                          <strong>CPU Usage (cores): </strong>{" "}
                          {typeof pod["cpu_usage (cores)"] === "string"
                            ? pod["cpu_usage (cores)"]
                                .split(" ")
                                .slice(0, 5)
                                .join(" ") + "..."
                            : pod["cpu_usage (cores)"]}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span title={String(pod["memory_usage (MBs)"])}>
                          <strong>CPU Usage (MBs): </strong>{" "}
                          {typeof pod["memory_usage (MBs)"] === "string"
                            ? pod["memory_usage (MBs)"]
                                .split(" ")
                                .slice(0, 5)
                                .join(" ") + "..."
                            : pod["memory_usage (MBs)"]}
                        </span>
                      </div>
                    </Card.Text>
                    <Button
                      variant="primary"
                      size="sm"
                      className="w-100"
                      onClick={() => setSelectedPod(pod)}
                    >
                      View Logs
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>

        <Modal
          show={!!selectedPod}
          onHide={() => setSelectedPod(null)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Logs - {selectedPod?.pod_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoadingLogs ? (
              <div className="text-center py-3">
                <Spinner animation="border" variant="primary" />
                <p>Fetching logs...</p>
              </div>
            ) : logsError ? (
              <ErrorAlert>Error fetching logs</ErrorAlert>
            ) : (
              <div
                ref={logContainerRef}
                className="log-container"
                style={{
                  height: "400px",
                  overflowY: "auto",
                  fontSize: "14px",
                  backgroundColor: "#1e1e1e",
                  padding: "15px",
                  borderRadius: "5px",
                  fontFamily: "monospace",
                  color: "white",
                  whiteSpace: "pre-wrap",
                }}
              >
                {logsData?.length === 0 ? (
                  <p className="text-muted">No logs available.</p>
                ) : (
                  logsData?.map((log, index) => <p key={index}>{log}</p>)
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Deployments;
