import { createApi } from "@reduxjs/toolkit/query/react";
import { k8DeploymentsApiUrl } from "../constants/constants";
import { getCookieByDomain } from "../utils/utils";

export const deploymentsApi = createApi({
  reducerPath: "deploymentsApi",
  baseQuery: async () => ({ data: [] }), // Ensure initial data is an array
  keepUnusedDataFor: 86400,
  tagTypes: ["Pods", "Logs"],
  endpoints: (builder) => ({
    getDeploymentsDetail: builder.query({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        id,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;

          const token = getCookieByDomain("crave_jwt_access_token");

          const response = await fetch(
            `${k8DeploymentsApiUrl}/pod_status/${id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok || !response.body) {
            throw new Error("Failed to connect to SSE");
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          const readStream = async () => {
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              const text = decoder.decode(value, { stream: true });

              text.split("\n").forEach((line) => {
                if (line.startsWith("data: data")) {
                  try {
                    console.log(line, "line");
                    const podData = JSON.parse(
                      line.replace("data: data: ", "").trim()
                    );

                    updateCachedData((draft) => {
                      if (!Array.isArray(draft)) return;
                      const existingPodIndex = draft.findIndex(
                        (p) => p.pod_name === podData.pod_name
                      );
                      if (existingPodIndex !== -1) {
                        draft[existingPodIndex] = podData;
                      } else {
                        draft.push(podData);
                      }
                    });
                  } catch (error) {
                    console.error("Error parsing SSE data:", error);
                  }
                }
              });
            }
          };

          readStream();

          await cacheEntryRemoved;
          reader.cancel();
        } catch (error) {
          console.error("Error in SSE:", error);
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((pod) => ({ type: "Pods", id: pod.pod_name })),
              { type: "Pods", id: "PodsList" },
            ]
          : [{ type: "Pods", id: "PodsList" }],
    }),

    getPodLogs: builder.query({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        { pod_name, pod_namespace },
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;

          const token = getCookieByDomain("crave_jwt_access_token");

          const response = await fetch(
            `${k8DeploymentsApiUrl}/logs/${pod_name}/${pod_namespace}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok || !response.body) {
            throw new Error("Failed to connect to SSE logs");
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          const readStream = async () => {
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              const text = decoder.decode(value, { stream: true });

              text.split("\n").forEach((line) => {
                if (line.startsWith("data: ")) {
                  try {
                    updateCachedData((draft) => {
                      if (!Array.isArray(draft)) return;
                      draft.push(line.replace("data: ", "").trim());
                    });
                  } catch (error) {
                    console.error("Error parsing log data:", error);
                  }
                }
              });
            }
          };

          readStream();

          await cacheEntryRemoved;
          reader.cancel();
        } catch (error) {
          console.error("Error in SSE logs:", error);
        }
      },
      providesTags: (result, error, arg) => [
        { type: "Logs", id: `${arg.pod_name}-${arg.pod_namespace}` },
      ],
    }),
  }),
});

export const { useGetDeploymentsDetailQuery, useGetPodLogsQuery } =
  deploymentsApi;
