// /rest/v3/deployments/available-images/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { credentials, dfpBaseV3Url, env } from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const deploymentApi = createApi({
  reducerPath: "deploymentApi",

  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseV3Url,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getAvailableImages: builder.query({
      query: () => `deployments/available-images/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "AvailableImages", id })),
              { type: "AvailableImages", id: "AvailableImagesList" },
            ]
          : [{ type: "AvailableImages", id: "AvailableImagesList" }],
    }),
    addImage: builder.mutation({
      query: (body) => ({
        url: `deployments/available-images/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "AvailableImages", id: "AvailableImagesList" }],
    }),
    // /rest/v3/deployments/available-images/{image_id}
    updateImage: builder.mutation({
      query: ({ body, image_id }) => ({
        url: `deployments/available-images/${image_id}`,
        method: "PUT",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, image_id },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          deploymentApi.util.updateQueryData(
            "getAvailableImages",
            undefined,
            (draft) => {
              const copy = current(draft);
              console.log("🚀 ~ copy:", copy);

              const updated = copy.map((item) => {
                const newObj = { ...item };

                if (item.AVAILABLE_IMAGE_ID === Number(image_id)) {
                  newObj["IMAGE_NAME"] = body.IMAGE_NAME;
                  newObj["RELEASE_NOTES"] = body.RELEASE_NOTES;
                  newObj["ACTIVE"] = body.ACTIVE;

                  console.log(newObj, "mew");
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteImage: builder.mutation({
      query: (body) => ({
        url: `deployments/available-images/${body.image_id}`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;

        try {
          patchResult = dispatch(
            deploymentApi.util.updateQueryData(
              "getAvailableImages",
              undefined,
              (draft) => {
                const index = draft.findIndex((item) => {
                  return item.AVAILABLE_IMAGE_ID === body.image_id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),

    getAgentDeployments: builder.query({
      query: () => `deployments/agent-deployments/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "AgentDeployments", id })),
              { type: "AgentDeployments", id: "AgentDeploymentsList" },
            ]
          : [{ type: "AgentDeployments", id: "AgentDeploymentsList" }],
    }),

    // /rest/v3/deployments/agent-deployments/
    addDeployment: builder.mutation({
      query: (body) => ({
        url: `deployments/agent-deployments/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        { type: "AgentDeployments", id: "AgentDeploymentsList" },
      ],
    }),

    updateAgentDeployment: builder.mutation({
      query: (body) => ({
        url: `deployments/agent-deployments/${body.AGENT_DEPLOYMENT_ID}`,
        method: "PUT",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        const patchResult = dispatch(
          deploymentApi.util.updateQueryData(
            "getAgentDeployments",
            undefined,
            (draft) => {
              const copy = current(draft);

              const updated = copy.map((item) => {
                const newObj = structuredClone(item);
                if (
                  item.AGENT_DEPLOYMENT_ID === Number(body.AGENT_DEPLOYMENT_ID)
                ) {
                  newObj["AVAILABLE_IMAGE_ID"] = body.AVAILABLE_IMAGE_ID;
                  newObj["AGENT_ID"] = body.AGENT_ID;
                  newObj["ENVIRONMENT_NAME"] = body.ENVIRONMENT_NAME;
                  newObj["REPLICA_SET"] = body.REPLICA_SET;

                  newObj["CONFIG"]["build"]["hpa_config"] =
                    body.CONFIG.build.hpa_config;
                  newObj["CONFIG"]["build"]["image"] = body.CONFIG.build.image;
                  newObj["CONFIG"]["build"]["object_id"] =
                    body.CONFIG.build.object_id;
                  newObj["CONFIG"]["build"]["object_name"] =
                    body.CONFIG.build.object_name;
                  newObj["CONFIG"]["build"]["object_type"] =
                    body.CONFIG.build.object_type;

                  newObj["CONFIG"]["build"]["replicas"] =
                    body.CONFIG.build.replicas;

                  newObj["CONFIG"]["build"]["space_id"] =
                    body.CONFIG.build.space_id;

                  newObj["CONFIG"]["build"]["space_name"] =
                    body.CONFIG.build.space_name;

                  newObj["CONFIG"]["status"] = body.CONFIG.status;
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteDeployment: builder.mutation({
      query: (body) => ({
        url: `deployments/agent-deployments/${body.deployment_id}`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            deploymentApi.util.updateQueryData(
              "getAgentDeployments",
              undefined,
              (draft) => {
                const index = draft.findIndex((item) => {
                  console.log(item.AGENT_DEPLOYMENT_ID, body.deployment_id);

                  return item.AGENT_DEPLOYMENT_ID === body.deployment_id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),

    // team deployments
    getTeamDeployments: builder.query({
      query: () => `deployments/team-deployments/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "TeamDeployments", id })),
              { type: "TeamDeployments", id: "TeamDeploymentsList" },
            ]
          : [{ type: "TeamDeployments", id: "TeamDeploymentsList" }],
    }),

    addTeamDeployment: builder.mutation({
      query: (body) => ({
        url: `deployments/team-deployments/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "TeamDeployments", id: "TeamDeploymentsList" }],
    }),

    updateTeamDeployment: builder.mutation({
      query: (body) => ({
        url: `deployments/team-deployments/${body.TEAM_DEPLOYMENT_ID}`,
        method: "PUT",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          deploymentApi.util.updateQueryData(
            "getTeamDeployments",
            undefined,
            (draft) => {
              const updated = draft.map((item) => {
                if (
                  item.TEAM_DEPLOYMENT_ID === Number(body.TEAM_DEPLOYMENT_ID)
                ) {
                  const newObj = structuredClone(item);

                  newObj.CONFIG.status = body.CONFIG.status;
                  newObj.AVAILABLE_IMAGE_ID = body.AVAILABLE_IMAGE_ID;
                  newObj.WORKFORCE_ID = body.WORKFORCE_ID;
                  newObj.ENVIRONMENT_NAME = body.ENVIRONMENT_NAME;
                  newObj.REPLICA_SET = body.REPLICA_SET;

                  if (
                    body.CONFIG.build.hpa_config.enabled &&
                    body.CONFIG.build.hpa_config.enabled !== "false"
                  ) {
                    newObj.CONFIG.build.hpa_config = {
                      enabled: body.CONFIG.build.hpa_config.enabled,
                      minReplicas: body.CONFIG.build.hpa_config.minReplicas,
                      maxReplicas: body.CONFIG.build.hpa_config.maxReplicas,
                      targetMemoryUtilizationPercentage:
                        body.CONFIG.build.hpa_config
                          .targetMemoryUtilizationPercentage,
                      targetCPUUtilizationPercentage:
                        body.CONFIG.build.hpa_config
                          .targetCPUUtilizationPercentage,
                    };
                  }

                  newObj.CONFIG.build.image = body.CONFIG.build.image;
                  newObj.CONFIG.build.object_id = body.CONFIG.build.object_id;
                  newObj.CONFIG.build.object_name =
                    body.CONFIG.build.object_name;
                  newObj.CONFIG.build.object_type =
                    body.CONFIG.build.object_type;
                  newObj.CONFIG.build.replicas = body.CONFIG.build.replicas;
                  newObj.CONFIG.build.space_id = body.CONFIG.build.space_id;
                  newObj.CONFIG.build.space_name = body.CONFIG.build.space_name;

                  return newObj;
                }
                return item;
              });

              Object.assign(draft, updated);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteTeamDeployment: builder.mutation({
      query: (body) => ({
        url: `deployments/team-deployments/${body.deployment_id}`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            deploymentApi.util.updateQueryData(
              "getTeamDeployments",
              undefined,
              (draft) => {
                const index = draft.findIndex((item) => {
                  console.log(item.TEAM_DEPLOYMENT_ID, body.deployment_id);

                  return item.TEAM_DEPLOYMENT_ID === body.deployment_id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),

    // /rest/v3/k8s_deployments/deploy

    addk8Deployment: builder.mutation({
      query: (body) => ({
        url: `k8s_deployments/deploy`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      // invalidatesTags: [{ type: "TeamDeployments", id: "TeamDeploymentsList" }],
    }),

    removek8Deployment: builder.mutation({
      query: (body) => ({
        url: `k8s_deployments/undeploy`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      // invalidatesTags: [{ type: "TeamDeployments", id: "TeamDeploymentsList" }],
    }),
  }),
});

export const {
  useGetAvailableImagesQuery,
  useAddImageMutation,
  useUpdateImageMutation,
  useDeleteImageMutation,
  useGetAgentDeploymentsQuery,
  useAddDeploymentMutation,
  useDeleteDeploymentMutation,
  useAddTeamDeploymentMutation,
  useGetTeamDeploymentsQuery,
  useDeleteTeamDeploymentMutation,
  useAddk8DeploymentMutation,
  useUpdateAgentDeploymentMutation,
  useRemovek8DeploymentMutation,
  useUpdateTeamDeploymentMutation,
} = deploymentApi;
