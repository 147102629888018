import React from "react";
import { Controller } from "react-hook-form";

const InputField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  disabled = false,
  icon = false,
  onIconClick = null,
}) => (
  <div className="form-group position-relative">
    {label && <label htmlFor="input-field">{label}</label>}
    <input
      type={type}
      value={value}
      name={name}
      className="form-control"
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
    />
    {icon && (
      <span
        className="position-absolute top-50 end-0 translate-middle-y me-3"
        style={{ cursor: "pointer", marginTop: "10px" }}
        onClick={onIconClick}
      >
        <i style={{ fontSize: "24px" }} className={`${icon}`}></i>
      </span>
    )}
  </div>
);
export default InputField;

export const RadioField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  mainLabel,
}) => (
  <div className="form-group">
    {mainLabel && (
      <label
        htmlFor="input-field"
        className=""
        style={{ display: "block" }}
      ></label>
    )}
    <div style={{ marginTop: "28px" }}>
      {label && (
        <label htmlFor="input-field" className="form-check-label">
          {label}
        </label>
      )}
      <input
        type={type}
        checked={value}
        name={name}
        className="form-check-input mx-3"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  </div>
);

export const SelectInput = ({
  options,
  value,
  onChange,
  label,
  name,
  withSelect = false,
}) => {
  return (
    <div className="form-group">
      {label && <label htmlFor="input-field">{label}</label>}

      <select
        name={name}
        className="form-select"
        value={value}
        onChange={onChange}
      >
        {withSelect && <option value="">Select</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const Textarea = ({
  value,
  label,
  name,
  placeholder,
  onChange,
  rows = 5,
  cols = 20,
}) => (
  <div className="form-group">
    {label && <label htmlFor="input-field">{label}</label>}

    <textarea
      name={name}
      cols={cols}
      rows={rows}
      className="form-control"
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    ></textarea>
  </div>
);

export const HookInputField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  disabled = false,
  icon = false,
  onIconClick = null,
  register,
  required,
  setValue,
}) => (
  <div className="form-group position-relative">
    {label && <label htmlFor="input-field">{label}</label>}
    <input
      type={type}
      value={value}
      name={name}
      className="form-control"
      placeholder={placeholder}
      disabled={disabled}
      {...register(name, { required })}
      onChange={(e) => {
        onChange && onChange(e); // Call the onChange function if provided
        setValue(e.target.value); // Update the value using setValue
      }}
    />
    {icon && (
      <span
        className="position-absolute top-50 end-0 translate-middle-y me-3"
        style={{ cursor: "pointer", marginTop: "10px" }}
        onClick={onIconClick}
      >
        <i style={{ fontSize: "24px" }} className={`${icon}`}></i>
      </span>
    )}
  </div>
);

export const Dropdown = ({
  name,
  control,
  options,
  label,
  defaultValue,
  rules,
  icon = null,
  onIconClick = null,
}) => {
  return (
    <div className="mb-3 position-relative">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
          rules={rules}
          render={({ field, fieldState }) => (
            <>
              <select
                {...field}
                className={`form-control border rounded-md p-2 w-full ${
                  fieldState.error ? "border-red-500" : "border-gray-300"
                }`}
              >
                <option value="" disabled>
                  Select an option
                </option>
                {options.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
              {icon && (
                <span
                  className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                  style={{ marginTop: "10px" }}
                  onClick={onIconClick}
                >
                  <i style={{ fontSize: "20px" }} className={icon}></i>
                </span>
              )}
              {fieldState.error && (
                <p className="text-red-500 invalid-feedback text-sm">
                  {fieldState.error.message}
                </p>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};

export const TextArea = ({
  name,
  control,
  label,
  defaultValue,
  rules,
  placeholder,
  icon = null,
  onIconClick = null,
}) => {
  return (
    <div className="mb-3 position-relative">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
          rules={rules}
          render={({ field, fieldState }) => (
            <>
              <textarea
                {...field}
                placeholder={placeholder || "Enter text here..."}
                className={`form-control border rounded-md p-2 w-full resize-none ${
                  fieldState.error ? "border-red-500" : "border-gray-300"
                }`}
                rows="4"
              />
              {icon && (
                <span
                  className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                  style={{ marginTop: "10px" }}
                  onClick={onIconClick}
                >
                  <i style={{ fontSize: "20px" }} className={icon}></i>
                </span>
              )}
              {fieldState.error && (
                <p className="text-red-500 invalid-feedback text-sm">
                  {fieldState.error.message}
                </p>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};

export const TextField = ({
  name,
  control,
  label,
  placeholder,
  rules = {},
  icon = null,
  onIconClick = null,
}) => (
  <div className="mb-3 relative">
    {label && (
      <label className="block text-sm font-medium text-gray-700" htmlFor={name}>
        {label}
      </label>
    )}
    <div className="relative">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <input
              {...field}
              type="text"
              className={`form-control border rounded-md p-2 w-full ${
                error ? "border-red-500" : "border-gray-300"
              }`}
              placeholder={placeholder}
            />
            {icon && (
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                style={{ marginTop: "10px" }}
                onClick={onIconClick}
              >
                <i style={{ fontSize: "20px" }} className={icon}></i>
              </span>
            )}
            {error && (
              <p className="text-red-500 invalid-feedback invalid-feedback text-sm">
                {error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  </div>
);

export const NumberField = ({
  name,
  control,
  label,
  placeholder,
  rules = {},
  icon = null,
  onIconClick = null,
  allowNegative = true, // Default: Allow negative values
  allowPositive = true, // Default: Allow positive values
  allowZero = true, // Default: Allow 0
}) => (
  <div className="mb-3 relative">
    {label && (
      <label className="block text-sm font-medium text-gray-700" htmlFor={name}>
        {label}
      </label>
    )}
    <div className="relative">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => (
          <>
            <input
              ref={ref}
              type="number"
              className={`form-control border rounded-md p-2 w-full ${
                error ? "border-red-500" : "border-gray-300"
              }`}
              placeholder={placeholder}
              value={value ?? ""}
              min={!allowNegative ? "1" : undefined} // Prevent negatives if not allowed
              onKeyDown={(e) => {
                if (!allowNegative && e.key === "-") e.preventDefault();
                if (!allowPositive && e.key.match(/[0-9]/)) e.preventDefault();
                if (e.key === "e") e.preventDefault(); // Prevent exponent notation
              }}
              onChange={(e) => {
                let newValue = e.target.value;

                // Allow clearing the input
                if (newValue === "") {
                  onChange("");
                  return;
                }

                // Block standalone "0" if allowZero is false
                if (!allowZero && newValue === "0") {
                  return;
                }

                // Block leading zeros like 01, 002
                if (/^0\d/.test(newValue)) {
                  return;
                }

                // Ensure number follows positive/negative rules
                const parsedValue = parseInt(newValue, 10);
                if (
                  (allowNegative || parsedValue > 0) &&
                  (allowPositive || parsedValue < 0)
                ) {
                  onChange(newValue);
                }
              }}
              onBlur={onBlur}
            />
            {icon && (
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                style={{ marginTop: "10px" }}
                onClick={onIconClick}
              >
                <i style={{ fontSize: "20px" }} className={icon}></i>
              </span>
            )}
            {error && (
              <p className="text-red-500 invalid-feedback text-sm">
                {error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  </div>
);
