import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./backbutton.module.css";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.backBtnWrapper}>
      <button className={styles.backBtn} onClick={() => navigate(-1)}>
        <i className="ti ti-arrow-left"></i> Back
      </button>
    </div>
  );
};

export default BackButton;
