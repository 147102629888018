import React, { useEffect, useRef, useState } from "react";

import AgentStep1 from "../Agents/AgentStep1";

import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import { SelectInput } from "../../Components/Input/Input";

import { isJSON, isObject } from "../../utils/utils";
import { agentObj } from "../Agents/agentObj";
import AgentStep2 from "../Agents/AgentStep2";
import { createPortal } from "react-dom";
import { agentOptValues } from "../../utils/options";

const AgentModifyModal = ({
  agentsData,
  setOpenAgentModifyModal,
  agents,
  modelData,
  actionData,
  setModifiedAgents,
  receiverConfig,
  selectedAgents,
}) => {
  const editorRef = useRef();

  const [activeStep, setActiveStep] = useState(1);
  const [agentConfig, setAgentConfig] = useState(agentObj);
  const [modifiedAgent, setModifiedAgent] = useState();

  const [agentID, setAgentID] = useState("");

  const [agent, setAgent] = useState({
    title: "",
    framework: "",
    desc: "",
    stage: "",
    share: false,
    collection: "",
    type: "",
    space_id: "",
  });

  const [agentError, setAgentError] = useState({
    title: "",
    framework: "",
    desc: "",
    stage: "",
  });

  const [agentConfigError, setAgentConfigError] = useState({
    model: "",
  });

  const agentsOptData = agentsData.filter((agent) => {
    // Check if the agent exists in modifiedAgents
    const isInModifiedAgents =
      receiverConfig.groupchat_config?.modifiedAgents?.some(
        (modifiedAgent) => modifiedAgent.agent_id === agent.agent_id
      );

    // Check if the agent exists in agents
    const isInAgents = receiverConfig.groupchat_config?.agents?.some(
      (existingAgent) => existingAgent.agent_id === agent.agent_id
    );

    // Return true if the agent is found in either modifiedAgents or agents
    return isInModifiedAgents || isInAgents;
  });

  const agentOptions =
    selectedAgents.length > 0
      ? agentOptValues(selectedAgents, agents)
      : agentOptValues(agentsOptData, agents);

  useEffect(() => {
    if (agentID) {
      setActiveStep(1);
      const currentAgent = Number(agentID);
      const alreadyModified = receiverConfig.groupchat_config?.modifiedAgents;
      const modificationExist = alreadyModified?.find(
        (item) => item.agent_id === currentAgent
      );
      const data = agentsData.find((item) => item.agent_id === currentAgent);

      if (modificationExist) {
        const config = { ...modificationExist };

        setAgent({
          title: data.name,
          framework: modificationExist.framework,
          desc: modificationExist.description,
          stage: data.agent_versions[0].stage,
          share: modificationExist.share,
          space_id: modificationExist.space_id,
        });
        if (typeof config.config === "string" && isJSON(config.config)) {
          config["config"] = JSON.parse(config.config);
          config["name"] = config.config.name;

          setAgentConfig(transformObject(config));
        } else {
          config["name"] = config.config.name;

          setAgentConfig(transformObject(config));
        }
        // setModifiedAgent(data);
      } else if (data) {
        setAgent({
          title: data.name,
          framework: data.framework,
          desc: data.description,
          stage: data.agent_versions[0].stage,
          share: JSON.parse(data.share?.toLowerCase()),
          space_id: data.space_id,
        });
        if (
          typeof data.agent_versions[0].config === "string" &&
          isJSON(data.agent_versions[0].config)
        ) {
          setAgentConfig(JSON.parse(data.agent_versions[0].config));
        }
        setModifiedAgent(data);
      }
    } else {
      setAgent({
        title: "",
        framework: "",
        desc: "",
        stage: "",
        share: false,
        collection: "",
        type: "",
      });
      setAgentConfig(agentObj);
    }
  }, [agentID, agentsData]);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;

    setTimeout(() => {
      if (editorRef.current) {
        const formatAction = editorRef.current.getAction(
          "editor.action.formatDocument"
        );
        if (formatAction) {
          formatAction.run();
        } else {
          console.error("Format document action is not available.");
        }
      }
    }, 300);
  };

  const handlePrev = () => {
    setActiveStep(1);
  };

  const handleValidation = () => {
    let valid = true;
    let newErrors = {};

    if (activeStep === 1) {
      for (const field in agentError) {
        newErrors[field] = "";
      }
      for (const field in agentError) {
        if (!agent[field]) {
          valid = false;
          newErrors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`;
        }
      }
      if (!valid) {
        setAgentError(newErrors);
      }
    } else if (activeStep === 2) {
      if (agentConfig.config.llm_config.config_list.length <= 0) {
        console.log(
          "🚀 ~ handleValidation ~ agentConfig.config.llm_config.config_list.length:",
          agentConfig.config.llm_config.config_list.length
        );
        valid = false;
        for (const field in agentConfigError) {
          newErrors[field] = "";
        }
        newErrors.model = "Please select at least one model";

        setAgentConfigError(newErrors);
      }
    }
    return valid;
  };

  const handleNext = () => {
    if (handleValidation()) {
      setActiveStep(activeStep + 1);
      setAgentError({
        title: "",
        framework: "",
        desc: "",
        stage: "",
      });
    }
  };

  // useEffect(() => {
  //   console.log("🚀 ~ useEffect ~ agentConfig:", agentConfig);
  // }, [agentConfig]);

  function saveValue() {
    // setAgentConfig((prevSpace) => ({
    //   ...prevSpace,
    //   name: agent.title,
    //   description: agent.desc,
    // }));

    if (handleValidation()) {
      const newModifiedAgent = {
        agent_id: Number(agentID),
        space_id: agent.space_id,
        description: agent.desc,
        framework: agent.framework,
        share: agent.share.toString(),
        parent_id: 0,
        platform_default: true,
        config: agentConfig.config,
        type: agentConfig.type,
        skills: agentConfig.skills,
      };

      if (isObject(newModifiedAgent.config.llm_config)) {
        const model = modelData.filter((model) =>
          newModifiedAgent.config.llm_config.config_list.includes(
            model.model_id
          )
        );

        const flattenedModel = model.map((item) => ({
          model_id: item.model_id,
          space_id: item.space_id,
          model: item.model,
          description: item.description,
          api_key: item.config.apiKey,
          api_type: item.config.apiType,
          api_version: item.config.apiVersion,
          base_url: item.config.baseUrl,
        }));

        newModifiedAgent.config.llm_config.config_list = flattenedModel;
      }
      newModifiedAgent.config["name"] = agentConfig.name;

      if (newModifiedAgent.skills && newModifiedAgent.skills.length > 0) {
        const actions = actionData.filter((action) =>
          newModifiedAgent.skills.includes(action.action_id)
        );

        const formattedSkills = actions.map((item) => {
          const newFormat = { ...item };
          const selectedConfig =
            typeof item.actions_version[0].config === "string" &&
            isJSON(item.actions_version[0].config)
              ? JSON.parse(item.actions_version[0].config)
              : item.actions_version[0].config;

          newFormat.content = selectedConfig.content;

          delete newFormat["actions_version"];

          return newFormat;
        });

        newModifiedAgent.skills = formattedSkills;
      }

      setAgentID("");
      setActiveStep(1);
      setModifiedAgents((prevAgents) => [...prevAgents, newModifiedAgent]);
    }
  }

  return createPortal(
    <div
      className="modal fade show "
      id="exLargeModal"
      tabIndex={-1}
      style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className=" modal-dialog modal-xl modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel4">
              Modify Agents
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpenAgentModifyModal(false)}
            />
          </div>
          <div className="modal-body">
            <FieldWrapper>
              <SelectInput
                options={agentOptions}
                value={agentID}
                onChange={(e) => setAgentID(e.target.value)}
                label="Select Agent"
                name={"agent"}
                withSelect={true}
              />
            </FieldWrapper>
            <hr className="my-6 mx-n4" />

            {activeStep === 1 && (
              <AgentStep1
                agent={agent}
                setAgent={setAgent}
                error={agentError}
                handleNext={handleNext}
                // heading={false}
              />
            )}

            {activeStep === 2 && (
              <AgentStep2
                showValue={saveValue}
                handleEditorDidMount={handleEditorDidMount}
                agentConfig={agentConfig}
                modelData={modelData}
                actionData={actionData}
                handlePrev={handlePrev}
                setAgentConfig={setAgentConfig}
                addAgentInfo={false}
                updateAgentInfo={false}
                error={agentConfigError}
              />
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

const transformObject = (obj) => {
  // Transform skills array to only contain action_id
  const transformedSkills = obj.skills.map((skill) => skill.action_id);

  // Check if llm_config is an object and transform config_list if it exists
  const transformedLlmConfig =
    typeof obj.config.llm_config === "object" && obj.config.llm_config !== null
      ? {
          ...obj.config.llm_config,
          config_list:
            obj.config.llm_config.config_list?.map(
              (config) => config.model_id
            ) || [],
        }
      : obj.config.llm_config; // Preserve llm_config if it's a boolean or null

  // Return a new object with the transformations applied
  return {
    ...obj,
    skills: transformedSkills,
    config: {
      ...obj.config,
      llm_config: transformedLlmConfig,
    },
  };
};

export default AgentModifyModal;
