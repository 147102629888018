export const envTypes = {
  development: "development",
  staging: "staging",
  production: "production",
};

export const ROLE_IDS = {
  PLATFORM_ADMIN: 9,
  SPACE_ADMIN: 7,
  VIEWER: 8,
  CREATOR: 10,
};

const env = envTypes.production;
let dfpBaseUrl,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  dfpAdminUrl,
  aibotApiStdUrl,
  aibotApiUrl,
  heliosBot,
  apiDomain,
  credentials,
  dfpBaseV3Url,
  dasboardLink,
  k8DeploymentsApiUrl;

if (env === envTypes.development) {
  dfpBaseUrl = "http://localhost:8002/rest/v2/standard/";
  dfpApi = "http://localhost:8002/rest/v2/";
  subDomain = "/dfp-admin";
  loginPath = "http://localhost:8000/login_crave";
  domain = "http://localhost:3000/";
  dfpAdminUrl = "http://localhost:3000/dfp-admin";
  aibotApiUrl = "http://localhost:8002/rest/v2/realm/";
  aibotApiStdUrl = "http://localhost:8002/rest/v2/realm/";
  k8DeploymentsApiUrl = "http://localhost:8002/rest/v3/k8s_deployments";
  heliosBot = "http://localhost:3001";
  apiDomain = "http://localhost:8002/";
  credentials = "include";
  dasboardLink = "https://dfp-api.nonprod.yumds.com/chat-analysis";
  dfpBaseV3Url = "http://localhost:8006/rest/v3/";
}
if (env === envTypes.staging) {
  dfpBaseUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/standard/";
  dfpApi = "https://dfp-api.nonprod.yumds.com/rest/v2/";
  subDomain = "/dfp-admin";
  loginPath = "https://dfp-api.nonprod.yumds.com/login_crave";
  domain = "https://dfp.nonprod.yumds.com";
  dfpAdminUrl = "https://dfp.nonprod.yumds.com/dfp-admin/";
  aibotApiUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/realm/";
  aibotApiStdUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/realm/";
  k8DeploymentsApiUrl =
    "https://dfp-api.nonprod.yumds.com/rest/v3/k8s_deployments";
  heliosBot = "https://re-api.nonprod.yumds.com";
  apiDomain = "https://dfp-api.nonprod.yumds.com/";
  credentials = "include";
  dasboardLink = "https://dfp-api.nonprod.yumds.com/chat-analysis";
  dfpBaseV3Url = "https://dfp-api.nonprod.yumds.com/rest/v3/";
}

if (env === envTypes.production) {
  dfpBaseUrl = "https://dfp-api.yumds.com/rest/v2/standard/";
  dfpApi = "https://dfp-api.yumds.com/rest/v2/";
  subDomain = "/dfp-admin";
  loginPath = "https://dfp-api.yumds.com/login_crave";
  domain = "https://dfp.yumds.com/";
  dfpAdminUrl = "https://dfp.yumds.com/dfp-admin/";
  aibotApiUrl = "https://dfp-api.yumds.com/rest/v2/realm/";
  aibotApiStdUrl = "https://dfp-api.yumds.com/rest/v2/realm/";
  k8DeploymentsApiUrl = "https://dfp-api.yumds.com/rest/v3/k8s_deployments";
  heliosBot = "https://aibot.yumds.com";
  apiDomain = "https://dfp-api.yumds.com/";
  credentials = "include";
  dasboardLink = "https://dfp-api.yumds.com/chat-analysis";
  dfpBaseV3Url = "https://dfp-api.yumds.com/rest/v3/";
}

export {
  dfpBaseUrl,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  dfpAdminUrl,
  aibotApiUrl,
  aibotApiStdUrl,
  heliosBot,
  apiDomain,
  env,
  credentials,
  dasboardLink,
  dfpBaseV3Url,
  k8DeploymentsApiUrl,
};
