import React, { useState } from "react";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";
import { Col, Row } from "react-bootstrap";
import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import InputField, {
  RadioField,
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";
import FullscreenEditor from "../../Components/FullScreenEditor/FullScreenEditor.jsx";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import { isJSON } from "validator";
import { getNestedValue } from "../../utils/utils";
import { generateSenderConfig } from "./genrateSenderConfig";
import {
  actionOptValues,
  humanOptionValue,
  modelOptValues,
} from "../../utils/options";
import { jsonEditorOptions } from "../../utils/jsonEditorOption";

const animatedComponents = makeAnimated();

const WorkforceStep3 = ({
  handleEditorDidMount,
  senderConfig,
  validateActionInfo,
  modelData,
  actionData,
  isFetchingModel,
  isFetchingAction,
  setSenderConfig,
  handlePrev,
  formatCode,
  handleNext,
  agentsData,
  isFetchingAgents,
  addActionInfo,
  updateActionInfo,
  showValue,
  error,
}) => {
  const [viewConfig, setViewConfig] = useState(false);
  const formValues = generateSenderConfig(senderConfig);

  // const speaker_selection_option = [
  //   { value: "auto", label: "Auto" },
  //   { value: "manual    ", label: "Manual" },
  //   { value: "random", label: "Random" },
  //   { value: "round_robin", label: "Round Robin" },
  // ];

  // const repeat_speaker_option = [
  //   { value: "", label: "Select" },
  //   { value: "true", label: "True" },
  //   { value: "false", label: "False" },
  // ];

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setSenderConfig((prevConfig) => {
      if (name === "use_llm_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_llm_config: checked,
            llm_config: checked
              ? {
                  cache_seed: null,
                  config_list: [],
                  temperature: 0.1,
                  timeout: null,
                }
              : false,
          },
        };
      } else if (name === "use_code_execution_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_code_execution_config: checked,
            code_execution_config: checked
              ? {
                  use_docker: false,
                  work_dir: null,
                }
              : false,
          },
        };
      }
      return prevConfig;
    });
  };

  const handleChange = (e) => {
    // if (name !== "code_execution_config") {
    const { name, value, type, checked } = e.target;

    setSenderConfig((prevConfig) => {
      let newValue = type === "checkbox" ? checked : value;

      if (name in prevConfig) {
        // newValue = newValue.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "");

        return {
          ...prevConfig,
          [name]: newValue,
        };
      } else if (name in prevConfig.config) {
        newValue =
          name === "name"
            ? newValue.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "")
            : newValue;

        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            [name]: newValue,
          },
        };
      } else if (name in prevConfig.config.code_execution_config) {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            code_execution_config: {
              ...prevConfig.config.code_execution_config,
              [name]: newValue,
            },
          },
        };
      } else if (name in prevConfig.config.llm_config) {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            llm_config: {
              ...prevConfig.config.llm_config,

              [name]: newValue,
            },
          },
        };
      }
      return prevConfig;
    });
    // } else {
    //   setSenderConfig((prevSpace) => ({
    //     ...prevSpace,
    //     [name]: !senderConfig.config.code_execution_config,
    //   }));
    //   console.log(
    //     "🚀 ~ setSenderConfig ~ code_execution_config:",
    //     senderConfig.config.code_execution_config
    //   );
    // }

    viewConfig && formatCode();
  };

  const modelOptions = modelOptValues(modelData, formValues.configList);

  const modelDefaultValue = [
    ...modelOptions.filter((option) => option.isSelected),
  ];

  const actionOptions = actionOptValues(actionData, formValues.skills);
  console.log("🚀 ~ formValues:", formValues);
  console.log("🚀 ~ actionOptions:", actionOptions, actionData);

  const actionDefaultValue = [
    ...actionOptions.filter((option) => option.isSelected), // Preselected options
  ];

  const handleModelChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);

    const selectedItem = modelData.filter((item) =>
      selectedValues.includes(item.model_id)
    );
    const flattenedModel = selectedItem.map((item) => ({
      model_id: item.model_id,
      space_id: item.space_id,
      model: item.model,
      description: item.description,
      api_key: item.config.apiKey,
      api_type: item.config.apiType,
      api_version: item.config.apiVersion,
      base_url: item.config.baseUrl,
    }));
    setSenderConfig((prevConfig) => ({
      ...prevConfig,
      config: {
        ...prevConfig.config,
        llm_config: {
          ...prevConfig.config.llm_config,
          config_list: flattenedModel,
        },
      },
    }));
  };

  const handleActionChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);

    const selectedItem = actionData.filter((item) =>
      selectedValues.includes(item.action_id)
    );

    const formattedSkills = selectedItem.map((item) => {
      const newFormat = { ...item };
      const selectedConfig =
        typeof item.actions_version[0].config === "string" &&
        isJSON(item.actions_version[0].config)
          ? JSON.parse(item.actions_version[0].config)
          : item.actions_version[0].config;

      newFormat.content = selectedConfig.content;

      delete newFormat["actions_version"];

      return newFormat;
    });
    setSenderConfig((prevState) => ({
      ...prevState,
      skills: formattedSkills,
    }));
  };

  const handleEditorChange = (value) => {
    try {
      const parsedValue = JSON.parse(value);
      setSenderConfig(parsedValue);
      formatCode();
    } catch (error) {
      console.error("Invalid JSON input", error);
    }
  };

  // const handleNextClick = () => {
  //   handleNext(4);
  // };

  const handlePrevClick = () => {
    handlePrev(2);
  };

  const handleSubmit = (validateConfig = true) => {
    showValue(false, validateConfig);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CurrentStepHeading title="Sender" titleSummary="Enter basic details" />
        <div className="text-center mt-3">
          <button
            type="button"
            className="btn btn-sm btn-primary waves-effect waves-light"
            onClick={() => setViewConfig(!viewConfig)}
          >
            {viewConfig ? "Hide " : "View "} JSON <i className="ti ti-code"></i>
          </button>
        </div>
      </div>
      {!viewConfig ? (
        <>
          <FieldWrapper>
            <Textarea
              value={formValues.systemMessage}
              placeholder="Planner. Thank like a Finance Manager for a Quick Service Restaurant (QSR) chain. Suggest a plan. The plan may involve an engineer who can write python code, an executor who execute code, critic to review the code and a writer who doesn't write code but write the summary/insights of the findings. Explain the plan first. Be clear which step is performed by the engineer, which step is performed by the executor, which step is performed by the critic and which step is performed by the writer. You pass the plan to the engineer."
              label="System Message"
              name="system_message"
              onChange={handleChange}
              rows={7}
            />
          </FieldWrapper>

          <Row className="mb-3 ">
            <Col>
              <FieldWrapper errorMsg={error.name}>
                <InputField
                  type="text"
                  value={formValues.name}
                  placeholder="Name"
                  label="Name"
                  name="name"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.defaultAutoReply}
                  placeholder="default_auto_reply"
                  label="Default Auto Reply"
                  name="default_auto_reply"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.type}
                  placeholder="userproxy"
                  label="Type"
                  name="type"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <RadioField
                type="checkbox"
                value={formValues.useCodeExecutionConfig}
                placeholder=""
                label="Code Execution Config"
                name="use_code_execution_config"
                onChange={handleCheckboxChange}
                mainLabel="Code Execution Config"
              />
            </Col>
          </Row>

          {formValues.useCodeExecutionConfig && (
            <Row className="mb-3">
              <Col>
                <FieldWrapper>
                  <InputField
                    type="text"
                    value={formValues.codeExecutionConfig.workDir}
                    placeholder="work_dir"
                    label="Working Dir"
                    name="work_dir"
                    onChange={handleChange}
                  />
                </FieldWrapper>
              </Col>
              <Col>
                <RadioField
                  type="checkbox"
                  value={formValues.codeExecutionConfig.useDocker}
                  placeholder=""
                  label="Use Docker"
                  name="use_docker"
                  onChange={handleChange}
                  mainLabel="Use Docker"
                />
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.isTerminationMsg}
                  placeholder="is_termination_msg"
                  label="Is Termination Msg"
                  name="is_termination_msg"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <RadioField
                type="checkbox"
                value={formValues.useLlmConfig}
                placeholder=""
                label="LLM Config"
                name="use_llm_config"
                onChange={handleCheckboxChange}
                mainLabel="LLM Config"
              />
            </Col>
          </Row>

          {formValues.useLlmConfig && (
            <>
              <Row className="mb-3">
                <Col>
                  <FieldWrapper>
                    <label htmlFor="input-field">Select Model</label>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={modelOptions}
                      onChange={handleModelChange}
                      isLoading={isFetchingModel}
                      components={animatedComponents}
                      defaultValue={modelDefaultValue}
                    />
                  </FieldWrapper>
                </Col>
                <Col>
                  <FieldWrapper>
                    <InputField
                      type="number"
                      value={formValues.timeout}
                      placeholder="timeout"
                      label="Timeout"
                      name="timeout"
                      onChange={handleChange}
                    />
                  </FieldWrapper>
                </Col>
              </Row>
              <Row className="mb-3 ">
                <Col>
                  <FieldWrapper>
                    <InputField
                      type="number"
                      value={formValues.cacheSeed}
                      placeholder="cache_seed"
                      label="Cache Seed"
                      name="cache_seed"
                      onChange={handleChange}
                    />
                  </FieldWrapper>
                </Col>
                <Col>
                  <FieldWrapper>
                    <InputField
                      type="Number"
                      value={formValues.temperature}
                      placeholder="0.1"
                      label="Temperature"
                      name="temperature"
                      onChange={handleChange}
                    />
                  </FieldWrapper>
                </Col>
              </Row>
            </>
          )}

          <Row className="mb-3 ">
            <Col>
              <FieldWrapper>
                <label htmlFor="input-field">Select Action</label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={actionOptions}
                  onChange={handleActionChange}
                  isLoading={isFetchingAction}
                  components={animatedComponents}
                  defaultValue={actionDefaultValue}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Row className="mb-3 ">
            <Col>
              <SelectInput
                options={options}
                value={formValues.humanInputMode}
                onChange={handleChange}
                label="Human Input Mode"
                name={"human_input_mode"}
              />
            </Col>
            <Col>
              <FieldWrapper>
                <InputField
                  type="number"
                  value={formValues.maxConsecutiveAutoReply}
                  placeholder="max_consecutive_auto_reply"
                  label="Max Consecutive Auto Reply"
                  name="max_consecutive_auto_reply"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
          </Row>
          {/* <FieldWrapper>
            <label htmlFor="input-field">Select Collection</label>
            <SelectInput
              closeMenuOnSelect={false}
              options={collectionOptions}
              value={agent.collection}
              onChange={handleChange}
              isLoading={isFetchingCollection}
              components={animatedComponents}
              name={"collection"}
            />
          </FieldWrapper> */}

          <FieldWrapper>
            <Textarea
              value={formValues.description}
              placeholder="Default Group  Workflow"
              label="Description"
              name="description"
              onChange={handleChange}
            />
          </FieldWrapper>
        </>
      ) : (
        <FullscreenEditor
          height="50vh"
          defaultLanguage="json"
          defaultValue={senderConfig && JSON.stringify(senderConfig)}
          theme="vs-dark"
          options={jsonEditorOptions}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
        />
      )}

      {viewConfig && (
        <small style={{ color: "#1b09ec" }} className="mt-3">
          <i className="ti ti-info-circle"></i> Please note that the final JSON
          values will be taken from the code block below. You can add additional
          values as well.
        </small>
      )}

      <div className="col-12 d-flex justify-content-between mt-3">
        <SecondaryButton handleClick={handlePrevClick}>
          <i className="ti ti-arrow-left me-sm-1 me-0" /> Previous
        </SecondaryButton>

        {/* <PrimaryButton handleClick={handleNextClick}>Review</PrimaryButton> */}

        {/* <div>
          {addActionInfo.isLoading || updateActionInfo.isLoading ? (
            <LoadingButton buttonText="Saving..." variant="primary" />
          ) : (
            <PrimaryButton handleClick={() => handleSubmit(false)}>
              <i className="ti ti-save me-sm-1 me-0" /> Save
            </PrimaryButton>
          )}
          {validateActionInfo.isLoading ? (
            <LoadingButton buttonText="Validating..." />
          ) : (
            <PrimaryButton
              handleClick={() => handleSubmit(true)}
              variant="submit"
            >
              Validate & Submit
            </PrimaryButton>
          )}
        </div> */}
        {addActionInfo.isLoading || updateActionInfo.isLoading ? (
          <LoadingButton />
        ) : (
          <PrimaryButton handleClick={handleSubmit} variant="submit">
            Submit{" "}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

// const flattenActions = (actions) => {
//   return actions.flatMap((action) =>
//     action.actions_version.map((version) => ({
//       action_id: action.action_id,
//       space_id: action.space_id,
//       title: action.title,
//       description: action.description,
//       file_name: action.file_name,
//       content: action.content,
//       share: action.share,
//       parent_id: action.parent_id,
//       platform_default: action.platform_default,
//       update_date_time: action.update_date_time,
//       action_version_id: version.action_version_id,
//       version: version.version,
//       stage: version.stage,
//       version_content: version.content,
//       version_config: version.config,
//       version_update_date_time: version.update_date_time,
//     }))
//   );
// };

const options = humanOptionValue();

const dockerOpts = [
  { value: "", label: "Select" },
  { value: "true", label: "True" },
  { value: "false", label: "False" },
];

const llmConfigOpts = [
  { value: false, label: "False" },
  { value: true, label: "True" },
];

export default WorkforceStep3;
