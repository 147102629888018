import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

const useFilteredData = (data, searchText, filterByVal) => {
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    if (!isEmpty(searchText) && data && data.length > 0) {
      const matchingRes = filterByVal(data, searchText);
      setFilteredPosts(matchingRes);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
  }, [data, searchText, filterByVal]);

  return filteredPosts;
};

export const useSearchFilter = (data, searchText, filterByVal) => {
  console.log(data);

  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    if (!isEmpty(searchText) && Array.isArray(data)) {
      const matchingRes = filterByVal(data, searchText);
      setFilteredPosts(matchingRes);
    } else {
      setFilteredPosts(Array.isArray(data) ? [...data] : []);
    }
  }, [data, searchText, filterByVal]);

  return filteredPosts;
};

export default useFilteredData;
