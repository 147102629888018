import React from "react";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";
import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import InputField, {
  RadioField,
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";
import { Col, Row } from "react-bootstrap";
import { PrimaryButton } from "../../Components/Buttons/Buttons";
import {
  environmentOptionValue,
  agentFrameworkOptionValue,
} from "../../utils/options";

const AgentStep1 = ({ agent, setAgent, error, handleNext, heading = true }) => {
  const options = environmentOptionValue();
  const frameworkOptions = agentFrameworkOptionValue();

  // const typeOpts = [
  //   { value: "", label: "Select" },
  //   { value: "assistant", label: "Assistant" },
  //   { value: "rag-agent", label: "Rag Agent" },
  // ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "share") {
      setAgent((prevSpace) => ({
        ...prevSpace,
        [name]: value,
      }));
    } else {
      setAgent((prevSpace) => ({
        ...prevSpace,
        [name]: !agent.share,
      }));
    }
  };

  return (
    <>
      {heading && (
        <CurrentStepHeading title="Agent" titleSummary="Enter agent details" />
      )}
      <FieldWrapper errorMsg={error.title}>
        <InputField
          type="title"
          value={agent.title}
          placeholder="Title"
          label="Title"
          name="title"
          onChange={handleChange}
        />
      </FieldWrapper>
      <Row>
        <Col>
          <FieldWrapper errorMsg={error.stage}>
            <SelectInput
              options={options}
              value={agent.stage}
              onChange={handleChange}
              label="Select Stage"
              name={"stage"}
            />
          </FieldWrapper>
        </Col>
        <Col>
          <FieldWrapper errorMsg={error.framework}>
            <SelectInput
              options={frameworkOptions}
              value={agent.framework}
              onChange={handleChange}
              label="Select Framework"
              name={"framework"}
            />
          </FieldWrapper>
        </Col>
        <Col>
          <RadioField
            type="checkbox"
            value={agent.share}
            placeholder=""
            label="Share With Others"
            name="share"
            onChange={handleChange}
            mainLabel="Share"
          />
        </Col>
      </Row>

      <Row className=" ">
        <FieldWrapper errorMsg={error.framework}>
          <InputField
            type="hidden"
            value={agent.framework}
            placeholder="File"
            label=""
            name="framework"
            onChange={handleChange}
          />
        </FieldWrapper>
      </Row>

      {/* <Row className="mb-3"></Row> */}

      <FieldWrapper errorMsg={error.desc}>
        <Textarea
          value={agent.desc}
          placeholder="Desc..."
          label="Description"
          name="desc"
          onChange={handleChange}
        />
      </FieldWrapper>

      <div className="col-12 d-flex justify-content-between">
        <PrimaryButton handleClick={handleNext}>
          Next <i className="ti ti-arrow-right" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default AgentStep1;
