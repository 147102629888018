export const booleanOptions = (withSelect = false) => {
  const options = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  if (withSelect) {
    options.unshift({ value: "", label: "Select" });
  }
  return options;
};

export const stageOptValue = () => [
  { value: "", label: "Select" },
  { value: "last", label: "Last" },
  { value: "none", label: "None" },
  { value: "llm", label: "LLM" },
];

export const humanOptionValue = () => [
  { value: "NEVER", label: "NEVER" },
  // { value: "ALWAYS", label: "ALWAYS" },
];

export const speakerOptionValue = () => [
  { value: "auto", label: "Auto" },
  { value: "manual    ", label: "Manual" },
  { value: "random", label: "Random" },
  { value: "round_robin", label: "Round Robin" },
];

export const environmentOptionValue = () => [
  { value: "", label: "Select" },
  { value: "prod", label: "Prod" },
  { value: "non-prod", label: "Non-Prod" },
  { value: "test", label: "Test" },
];

export const agentFrameworkOptionValue = () => [
  { value: "autogen", label: "AutoGen" },
  { value: "pydantic-ai", label: "Pydantic AI" },
];

export const agentFrameworkTypeOptions = {
  autogen: [{ value: "assistant", label: "Assistant" }],
  "pydantic-ai": [
    { value: "basic-agent", label: "Basic Agent" },
    { value: "workflow-agent", label: "Workflow Agent" },
  ],
};

// Function to generate options for a given dataset
export const generateOptions = (
  data,
  keys,
  isSelectedKey = null,
  selectedValues = []
) => {
  // Extract values from selectedValues if it's an array of objects
  let selectedValueSet;
  if (
    selectedValues &&
    selectedValues.length > 0 &&
    typeof selectedValues[0] === "object"
  ) {
    selectedValueSet = new Set(selectedValues.map((obj) => obj[isSelectedKey]));
  } else {
    selectedValueSet = new Set(selectedValues);
  }

  return data.map((item) => {
    let option = {
      value: item[keys.value],
      // Handle label as a function or string
      label:
        typeof keys.label === "function" ? keys.label(item) : item[keys.label],
    };

    // Add additional keys if provided
    for (let key in keys) {
      if (key !== "value" && key !== "label") {
        option[key] = item[keys[key]];
      }
    }

    // Add isSelected key if applicable
    if (isSelectedKey) {
      option.isSelected = selectedValueSet.has(item[isSelectedKey]);
    }

    return option;
  });
};

// Usage for user options
export const userOptValues = (usersData, users = []) => {
  return generateOptions(
    usersData,
    {
      value: "user_id",
      label: (item) => `${item.user_name} (${item.user_email})`,
      name: "user_name",
      email: "user_email",
    },
    "user_id",
    users.map((user) => user.user_id) || []
  );
};

// Usage for collection options
export const collectionOptValues = (collectionData, collection_ids = []) => {
  return generateOptions(
    collectionData,
    {
      value: "collection_id",
      label: "collection_name",
      name: "collection_name",
    },
    "collection_id",
    collection_ids
  );
};

export const modelOptValues = (data, model_ids = []) => {
  return generateOptions(
    data,
    {
      value: "model_id",
      label: "model",
    },
    "model_id",
    model_ids
  );
};

export const agentOptValues = (data, ids = []) => {
  return generateOptions(
    data,
    {
      value: "agent_id",
      label: "name",
      space_id: "space_id",
    },
    "agent_id",
    ids
  );
};

export const actionOptValues = (data, ids = []) => {
  return generateOptions(
    data,
    {
      value: "action_id",
      label: "title",
    },
    "action_id",
    ids
  );
};
