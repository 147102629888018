import React from "react";
import { Dropdown, TextField, NumberField } from "../../Components/Input/Input";

import { PrimaryButton } from "../../Components/Buttons/Buttons";
import { Spinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";

import { useForm } from "react-hook-form";
import {
  useAddDeploymentMutation,
  useAddTeamDeploymentMutation,
} from "../../services/deploymentService";

const AddNewBuild = ({ buildObj, setShowAddNewBuild }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm({
    defaultValues: {
      hpa_config_enabled: "false",
    },
  });

  const hpaConfigEnabled = watch("hpa_config_enabled");

  const [addDeployment, addDeploymentInfo] = useAddDeploymentMutation();
  const [addTeamDeployment, addTeamDeploymentInfo] =
    useAddTeamDeploymentMutation();

  const handleCancel = () => {
    setShowAddNewBuild(false);
  };

  const onSubmit = (data) => {
    const body = {
      space_id: "36",
      space_name: "Test Space",
      object_id: buildObj.object_id,
      object_name: data.object_name,
      object_type: buildObj.object_type,
      image: buildObj.image,
      deployment_id: buildObj.deployment_id,
      cluster_name: buildObj.cluster_name,
      replicas: Number(buildObj.replicas),
      hpa_config: {},
    };
    if (data.hpa_config_enabled) {
      body["hpa_config"] = {
        enabled: true,
        minReplicas: Number(data.minReplicas),
        maxReplicas: Number(data.maxReplicas),
        targetMemoryUtilizationPercentage: Number(
          data.targetCPUUtilizationPercentage
        ),
        targetCPUUtilizationPercentage: Number(
          data.targetMemoryUtilizationPercentage
        ),
      };
    }
    console.log("🚀 ~ onSubmit ~ data:", data, buildObj);

    // const body = {
    //   AVAILABLE_IMAGE_ID: data.AVAILABLE_IMAGE_ID,

    //   ENVIRONMENT_NAME: data.ENVIRONMENT_NAME,
    //   REPLICA_SET: Number(data.REPLICA_SET),
    //   CONFIG: {},
    //   CREATE_ID: "Factory Admin",
    //   CREATE_PROCESS: "API",
    //   UPDATE_ID: "Factory Admin",
    //   UPDATE_PROCESS: "API",
    // };

    // // if (deploymentType === "agent") body["AGENT_ID"] = data.AGENT_ID;
    // // else body["WORKFORCE_ID"] = data.WORKFORCE_ID;

    // data.DEPLOYMENT_TYPE === "agent"
    //   ? addDeployment(body)
    //   : addTeamDeployment(body);
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasAddUser"
      aria-labelledby="offcanvasAddUserLabel"
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
          Add Pods
          {/* {newDeployment ? "Edit Deployment" : "Add Deployment"} */}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
        <form
          className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework"
          id="addNewUserForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate="novalidate"
        >
          <div className="mb-3 fv-plugins-icon-container">
            <TextField
              name="object_name"
              control={control}
              label="Object Name"
              rules={{ required: "Object name is required" }}
            />
          </div>

          <div className="mb-3 fv-plugins-icon-container">
            <div className="mb-3 fv-plugins-icon-container">
              <Dropdown
                name="hpa_config_enabled"
                control={control}
                label="HPA Config"
                options={[
                  { label: "True", value: "true" },
                  { label: "False", value: "false" },
                ]}
                rules={{ required: "This field is required" }}
              />
            </div>
          </div>

          {hpaConfigEnabled === "true" && (
            <>
              <div className="mb-3 fv-plugins-icon-container">
                <NumberField
                  name="minReplicas"
                  control={control}
                  label="Min Replicas"
                  rules={{
                    required: "Min replica is required",
                    min: { value: 1, message: "Min replicas must be 1" },
                  }}
                />
              </div>
              <div className="mb-3 fv-plugins-icon-container">
                <NumberField
                  name="maxReplicas"
                  control={control}
                  label="Max Replicas"
                  rules={{
                    required: "Max replica is required",
                    min: { value: 1, message: "Max replicas must be 1" },
                  }}
                />
              </div>
              <div className="mb-3 fv-plugins-icon-container">
                <NumberField
                  name="targetMemoryUtilizationPercentage"
                  control={control}
                  label="Target Memory Utilization %"
                  rules={{
                    required: "Target Memory Utilization is required",
                    min: {
                      value: 1,
                      message: "Target Memory Utilization must be 1",
                    },
                  }}
                />
              </div>
              <div className="mb-3 fv-plugins-icon-container">
                <NumberField
                  name="targetCPUUtilizationPercentage"
                  control={control}
                  label="Target Cpu Utilization %"
                  rules={{
                    required: "Replica Set is required",
                    min: { value: 1, message: "Replicas must be 1" },
                  }}
                />
              </div>
            </>
          )}

          {addDeploymentInfo.isLoading ? (
            <PrimaryButton
              handleClick={handleSubmit}
              type="submit"
              disabled={true}
            >
              Submitting
              <span style={{ marginLeft: "5px" }}>
                <Spinner classes={"spinner-border-sm"} />
              </span>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              handleClick={(e) => {
                handleSubmit(e);
              }}
              type="submit"
            >
              Submit
            </PrimaryButton>
          )}
        </form>

        <div className="mt-3">
          {addDeploymentInfo.isSuccess && (
            <SuccessAlert>
              {console.log(
                addDeploymentInfo.isSuccess,
                "addSpaceInfo.isSuccess"
              )}
              Deployment {addDeploymentInfo.isSuccess ? "Added" : "Updated"}{" "}
              Successfully
            </SuccessAlert>
          )}
          {addDeploymentInfo.isError && (
            <ErrorAlert>Some Error Occurred</ErrorAlert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewBuild;
