import React, { useState } from "react";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";

import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import InputField, {
  RadioField,
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";
import { Col, Row } from "react-bootstrap";
import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import FullscreenEditor from "../../Components/FullScreenEditor/FullScreenEditor.jsx";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { generateAgentConfig } from "./generateAgentConfig";
import {
  actionOptValues,
  agentFrameworkTypeOptions,
  humanOptionValue,
  modelOptValues,
} from "../../utils/options";
import { jsonEditorOptions } from "../../utils/jsonEditorOption";
import CodeEditor from "../../Components/TextEditor/TextEditor.jsx";

const animatedComponents = makeAnimated();

const AgentStep2 = ({
  agent,
  showValue,
  handleEditorDidMount,
  addAgentInfo,
  updateAgentInfo,
  agentConfig,
  collectionData,
  isFetchingCollection,
  modelData,
  actionData,
  isFetchingModel,
  isFetchingAction,
  handlePrev,
  setAgentConfig,
  error,
}) => {
  const [viewConfig, setViewConfig] = useState(false);

  const formValues = generateAgentConfig(agentConfig);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setAgentConfig((prevConfig) => {
      if (name === "use_llm_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_llm_config: checked,
            llm_config: checked
              ? {
                  cache_seed: null,
                  config_list: [],
                  temperature: 0.1,
                  timeout: null,
                }
              : false,
          },
        };
      } else if (name === "use_code_execution_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_code_execution_config: checked,
            code_execution_config: checked
              ? {
                  use_docker: false,
                  work_dir: null,
                }
              : false,
          },
        };
      }
      return prevConfig;
    });
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    let finalValue = type === "checkbox" ? checked : value;

    if (type === "number") {
      finalValue = parseFloat(value);
    }

    setAgentConfig((prevConfig) => {
      if (name in prevConfig) {
        finalValue =
          name === "name"
            ? finalValue.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "")
            : finalValue;
        return {
          ...prevConfig,
          [name]: finalValue,
        };
      } else if (name in prevConfig.config) {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            [name]: finalValue,
          },
        };
      } else if (
        prevConfig.config.llm_config &&
        typeof prevConfig.config.llm_config === "object" &&
        name in prevConfig.config.llm_config
      ) {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            llm_config: {
              ...prevConfig.config.llm_config,
              [name]: finalValue,
            },
          },
        };
      } else if (
        prevConfig.config.code_execution_config &&
        typeof prevConfig.config.code_execution_config === "object" &&
        name in prevConfig.config.code_execution_config
      ) {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            code_execution_config: {
              ...prevConfig.config.code_execution_config,
              [name]: finalValue,
            },
          },
        };
      }

      // If property name doesn't match any, return previous state
      return prevConfig;
    });
  };

  const modelOptions = modelOptValues(modelData, formValues.configList);

  const modelDefaultValue = [
    ...modelOptions.filter((option) => option.isSelected),
  ];

  const actionOptions = actionOptValues(actionData, formValues.skills);

  const actionDefaultValue = [
    ...actionOptions.filter((option) => option.isSelected), // Preselected options
  ];

  const collectionOptions = collectionData?.map(
    ({ collection_id, collection_name }) => ({
      value: collection_id,
      label: collection_name,
    })
  );

  collectionOptions?.unshift({
    value: "",
    label: "Select",
  });

  const handleModelChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setAgentConfig((prevAgentConfig) => ({
      ...prevAgentConfig,
      config: {
        ...prevAgentConfig.config,
        llm_config: {
          ...prevAgentConfig.config.llm_config,
          config_list: selectedValues,
        },
      },
    }));
  };

  const handleActionChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setAgentConfig((prevAgentConfig) => ({
      ...prevAgentConfig,
      skills: selectedValues,
    }));
  };

  const handleEditorChange = (value) => {
    try {
      const parsedValue = JSON.parse(value);
      setAgentConfig(parsedValue);
    } catch (error) {
      console.error("Invalid JSON input", error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CurrentStepHeading
          title="Configuration"
          titleSummary="Enter configuration in JSON format."
        />
        <div className="text-center mt-3">
          <button
            type="button"
            className="btn btn-sm btn-primary waves-effect waves-light"
            onClick={() => setViewConfig(!viewConfig)}
          >
            {viewConfig ? "Hide " : "View "} JSON <i className="ti ti-code"></i>
          </button>
        </div>
      </div>
      {!viewConfig ? (
        <>
          <FieldWrapper>
            <CodeEditor
              value={formValues.systemMessage}
              placeholder="Planner. Thank like a Finance Manager for a Quick Service Restaurant (QSR) chain. Suggest a plan. The plan may involve an engineer who can write python code, an executor who execute code, critic to review the code and a writer who doesn't write code but write the summary/insights of the findings. Explain the plan first. Be clear which step is performed by the engineer, which step is performed by the executor, which step is performed by the critic and which step is performed by the writer. You pass the plan to the engineer."
              label="System Message"
              name="system_message"
              onChange={handleChange}
              rows={7}
            />
          </FieldWrapper>
          <Row>
            <Col>
              <FieldWrapper errorMsg={error.name}>
                <InputField
                  type="text"
                  value={formValues.name}
                  placeholder="Planner"
                  label="Name"
                  name="name"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <FieldWrapper>
                <label>Type</label>
                <Select
                  value={
                    agentFrameworkTypeOptions[agent?.framework]?.find(
                      (option) => option.value === formValues?.type
                    ) || null
                  }
                  placeholder="Select Assistant Type"
                  onChange={(value) => {
                    const e = {
                      target: {
                        name: "type",
                        value: value?.value,
                        type: "text",
                        checked: false,
                      },
                    };
                    handleChange(e);
                  }}
                  options={agentFrameworkTypeOptions[agent?.framework] || []}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Row className="mb-3 ">
            <Col>
              <SelectInput
                options={options}
                value={formValues.humanInputMode}
                onChange={handleChange}
                label="Human Input Mode"
                name={"human_input_mode"}
              />
            </Col>

            <Col>
              <RadioField
                type="checkbox"
                value={formValues.useCodeExecutionConfig}
                placeholder=""
                label="Code Execution Config"
                name="use_code_execution_config"
                onChange={handleCheckboxChange}
                mainLabel="Code Execution Config"
              />
            </Col>
          </Row>
          {formValues.useCodeExecutionConfig && (
            <Row className="mb-3 ">
              <Col>
                <FieldWrapper>
                  <InputField
                    type="text"
                    value={formValues.workDir}
                    placeholder="work_dir"
                    label="Working Dir"
                    name="work_dir"
                    onChange={handleChange}
                  />
                </FieldWrapper>
              </Col>
              <Col>
                <RadioField
                  type="checkbox"
                  value={formValues.useDocker}
                  placeholder=""
                  label="Use Docker"
                  name="use_docker"
                  onChange={handleChange}
                  mainLabel="Use Docker"
                />
              </Col>
            </Row>
          )}
          <Row className="mb-3 ">
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.defaultAutoReply}
                  placeholder="default_auto_reply"
                  label="Default Auto Reply"
                  name="default_auto_reply"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.isTerminationMsg}
                  placeholder="is_termination_msg"
                  label="Is Termination Msg"
                  name="is_termination_msg"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Row className="mb-3 ">
            <Col>
              <FieldWrapper>
                <InputField
                  type="number"
                  value={formValues.maxConsecutiveAutoReply}
                  placeholder="max_consecutive_auto_reply"
                  label="Max Consecutive Auto Reply"
                  name="max_consecutive_auto_reply"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            {/* <Col>
              <RadioField
                type="checkbox"
                value={formValues.useLlmConfig}
                placeholder=""
                label="LLM Config"
                name="use_llm_config"
                onChange={handleCheckboxChange}
                mainLabel="LLM Config"
              />
            </Col> */}
            <Col>
              <FieldWrapper>
                <InputField
                  type="number"
                  value={formValues.cacheSeed}
                  placeholder="cache_seed"
                  label="Cache Seed"
                  name="cache_seed"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
          </Row>
          {/* {formValues.useLlmConfig && ( */}
          <>
            <Row className="mb-3 ">
              <Col>
                <FieldWrapper>
                  <InputField
                    type="Number"
                    value={formValues.temperature}
                    placeholder="temperature"
                    label="Temperature"
                    name="temperature"
                    onChange={handleChange}
                  />
                </FieldWrapper>
              </Col>
              <Col>
                <FieldWrapper>
                  <InputField
                    type="number"
                    value={formValues.timeout}
                    placeholder="timeout"
                    label="Timeout"
                    name="timeout"
                    onChange={handleChange}
                  />
                </FieldWrapper>
              </Col>
            </Row>
            <Row className="mb-3 ">
              <Col>
                <FieldWrapper errorMsg={error.model}>
                  <label htmlFor="input-field">Select Model</label>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={modelOptions}
                    onChange={handleModelChange}
                    isLoading={isFetchingModel}
                    components={animatedComponents}
                    defaultValue={modelDefaultValue}
                  />
                </FieldWrapper>
              </Col>
              <Col>
                <FieldWrapper>
                  <label htmlFor="input-field">Select Action</label>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={actionOptions}
                    onChange={handleActionChange}
                    isLoading={isFetchingAction}
                    components={animatedComponents}
                    defaultValue={actionDefaultValue}
                  />
                </FieldWrapper>
              </Col>
            </Row>
          </>
          {/* )} */}

          {/* <FieldWrapper>
              <label htmlFor="input-field">Select Collection</label>
              <SelectInput
                closeMenuOnSelect={false}
                options={collectionOptions}
                value={agent.collection}
                onChange={handleChange}
                isLoading={isFetchingCollection}
                components={animatedComponents}
                name={"collection"}
              />
            </FieldWrapper> */}
        </>
      ) : (
        <FullscreenEditor
          height="50vh"
          defaultLanguage="json"
          defaultValue={agentConfig && JSON.stringify(agentConfig)}
          theme="vs-dark"
          options={jsonEditorOptions}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
        />
      )}

      {viewConfig && (
        <small style={{ color: "#1b09ec" }} className="mt-3">
          <i className="ti ti-info-circle"></i> Please note that the final JSON
          values will be taken from the code block below. You can add additional
          values as well.
        </small>
      )}

      <div className="col-12 d-flex justify-content-between mt-3">
        <SecondaryButton handleClick={handlePrev}>
          <i className="ti ti-arrow-left me-sm-1 me-0" /> Previous
        </SecondaryButton>
        {addAgentInfo.isLoading || updateAgentInfo.isLoading ? (
          <LoadingButton />
        ) : (
          <PrimaryButton handleClick={showValue} variant="submit">
            Submit{" "}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

const options = humanOptionValue();

export default AgentStep2;
