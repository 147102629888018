import React from "react";
import { Dropdown, TextField, NumberField } from "../../Components/Input/Input";

import { PrimaryButton } from "../../Components/Buttons/Buttons";
import { Spinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";

import { useForm, useWatch } from "react-hook-form";
import {
  useAddDeploymentMutation,
  useAddTeamDeploymentMutation,
  useUpdateAgentDeploymentMutation,
  useUpdateTeamDeploymentMutation,
} from "../../services/deploymentService";
import { useSelector } from "react-redux";

const AddNewDeployment = ({
  setShowAddNewDeployment,
  editDeployment: newDeployment,
  agentData,
  availableImages,
  workforceData,
  deploymentType: currentDeploymentType,
}) => {
  console.log("🚀 ~ newDeployment:", newDeployment);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      AVAILABLE_IMAGE_ID: newDeployment?.data.AVAILABLE_IMAGE_ID || "",
      AGENT_ID: newDeployment?.data.AGENT_ID || "",
      // ENVIRONMENT_NAME: newDeployment?.ENVIRONMENT_NAME || "",
      REPLICA_SET: newDeployment?.data.REPLICA_SET ?? 1,
      WORKFORCE_ID: newDeployment?.data.WORKFORCE_ID,
      AVAILABLE_IMAGE_ID: newDeployment?.data.AVAILABLE_IMAGE_ID,

      DEPLOYMENT_TYPE: currentDeploymentType,
      hpa_config_enabled:
        newDeployment?.data.CONFIG?.build?.hpa_config &&
        Object.keys(newDeployment.data.CONFIG.build.hpa_config).length > 0
          ? "true"
          : "false",

      maxReplicas: newDeployment?.data.CONFIG?.build.hpa_config?.maxReplicas
        ? newDeployment?.data.CONFIG.build.hpa_config.maxReplicas
        : 1,
      minReplicas: newDeployment?.data.CONFIG?.build.hpa_config?.minReplicas
        ? newDeployment?.data.CONFIG.build.hpa_config.minReplicas
        : 1,
      targetCPUUtilizationPercentage:
        newDeployment?.data.CONFIG?.build?.hpa_config
          ?.targetCPUUtilizationPercentage,
      targetMemoryUtilizationPercentage:
        newDeployment?.data.CONFIG?.build?.hpa_config
          ?.targetMemoryUtilizationPercentage,
      object_name: newDeployment?.data.CONFIG?.build.object_name,
    },
  });

  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const deploymentType = watch("DEPLOYMENT_TYPE");
  const hpaConfigEnabled = watch("hpa_config_enabled");

  const [addDeployment, addDeploymentInfo] = useAddDeploymentMutation();
  const [updateAgentDeployment, updateAgentDeploymentInfo] =
    useUpdateAgentDeploymentMutation();

  const [addTeamDeployment, addTeamDeploymentInfo] =
    useAddTeamDeploymentMutation();
  const [updateTeamDeployment, updateTeamDeploymentInfo] =
    useUpdateTeamDeploymentMutation();

  const handleCancel = () => {
    setShowAddNewDeployment(false);
  };

  const onSubmit = (data) => {
    const body = {
      AVAILABLE_IMAGE_ID: data.AVAILABLE_IMAGE_ID,
      ENVIRONMENT_NAME: "",
      REPLICA_SET: Number(data.REPLICA_SET),
      CONFIG: {
        build: {
          space_id: currentSpace,
          space_name: "Test Space",

          object_name: data.object_name,

          image: data.AVAILABLE_IMAGE_ID,

          cluster_name: data.ENVIRONMENT_NAME,
          replicas: Number(data.REPLICA_SET),
          hpa_config: {},
        },
        status: false,
      },
      CREATE_ID: "Factory Admin",
      CREATE_PROCESS: "API",
      UPDATE_ID: "Factory Admin",
      UPDATE_PROCESS: "API",
    };

    if (deploymentType === "agent") {
      body["AGENT_ID"] = data.AGENT_ID;
      body["CONFIG"]["build"]["object_id"] = data.AGENT_ID;
      body["CONFIG"]["build"]["object_type"] = "agent";
    } else {
      body["WORKFORCE_ID"] = data.WORKFORCE_ID;
      body["CONFIG"]["build"]["object_id"] = data.WORKFORCE_ID;
      body["CONFIG"]["build"]["object_type"] = "team";
    }

    if (data.hpa_config_enabled && data.hpa_config_enabled !== "false") {
      body["CONFIG"]["build"]["hpa_config"] = {
        enabled: "true",
        minReplicas: Number(data.minReplicas),
        maxReplicas: Number(data.maxReplicas),
        targetMemoryUtilizationPercentage: Number(
          data.targetCPUUtilizationPercentage
        ),
        targetCPUUtilizationPercentage: Number(
          data.targetMemoryUtilizationPercentage
        ),
      };
    }

    // body["CONFIG"] = JSON.stringify(body["CONFIG"], null, 4);
    if (newDeployment) {
      if (data.DEPLOYMENT_TYPE === "agent") {
        body["AGENT_DEPLOYMENT_ID"] = String(
          newDeployment.data.AGENT_DEPLOYMENT_ID
        );

        body["AGENT_ID"] = String(body["AGENT_ID"]);
        body["AVAILABLE_IMAGE_ID"] = String(body["AVAILABLE_IMAGE_ID"]);

        console.log(body);
        updateAgentDeployment(body);
      } else {
        body["TEAM_DEPLOYMENT_ID"] = String(
          newDeployment.data.TEAM_DEPLOYMENT_ID
        );

        body["WORKFORCE_ID"] = String(body["WORKFORCE_ID"]);
        body["AVAILABLE_IMAGE_ID"] = String(body["AVAILABLE_IMAGE_ID"]);
        updateTeamDeployment(body);
        console.log(body);
      }
    } else {
      data.DEPLOYMENT_TYPE === "agent"
        ? addDeployment(body)
        : addTeamDeployment(body);
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasAddUser"
      aria-labelledby="offcanvasAddUserLabel"
      style={{ width: "33%" }}
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
          {newDeployment ? "Edit Deployment" : "Add Deployment"}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
        <form
          className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework"
          id="addNewUserForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate="novalidate"
        >
          {/* <div className="mb-3 fv-plugins-icon-container">
            <TextField
              name="ENVIRONMENT_NAME"
              control={control}
              label="Environment Name"
              rules={{ required: "Environment name is required" }}
            />
          </div> */}

          <div className="mb-3 fv-plugins-icon-container">
            <TextField
              name="object_name"
              control={control}
              label="Agent Planner"
              rules={{ required: "Object name is required" }}
            />
          </div>

          <div className="row">
            <div className="col-6">
              <div className="mb-3 fv-plugins-icon-container">
                <div className="mb-3 fv-plugins-icon-container">
                  <Dropdown
                    name="DEPLOYMENT_TYPE"
                    control={control}
                    label="Deployment Type"
                    options={[
                      { label: "Agent", value: "agent" },
                      { label: "Team", value: "teams" },
                    ]}
                    rules={{ required: "This field is required" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3 fv-plugins-icon-container">
                <NumberField
                  name="REPLICA_SET"
                  control={control}
                  label="Replica Set"
                  allowNegative={false}
                  rules={{
                    required: "Replica Set is required",
                    min: { value: 1, message: "Replicas must be 1" },
                  }}
                  allowZero={false}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="mb-3 fv-plugins-icon-container">
                <div className="mb-3 fv-plugins-icon-container">
                  <Dropdown
                    name="AVAILABLE_IMAGE_ID"
                    control={control}
                    label="Image"
                    options={availableImages?.map((item) => ({
                      label: item.IMAGE_NAME,
                      value: item.AVAILABLE_IMAGE_ID,
                    }))}
                    rules={{ required: "This field is required" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              {deploymentType === "teams" && (
                <div className="mb-3 fv-plugins-icon-container">
                  <div className="mb-3 fv-plugins-icon-container">
                    <Dropdown
                      name="WORKFORCE_ID"
                      control={control}
                      label="Workforce"
                      options={workforceData?.map((item) => ({
                        label: item.title,
                        value: item.workforce_id,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                  </div>
                </div>
              )}
              {deploymentType === "agent" && (
                <div className="mb-3 fv-plugins-icon-container">
                  <div className="mb-3 fv-plugins-icon-container">
                    <Dropdown
                      name="AGENT_ID"
                      control={control}
                      label="Agent ID"
                      options={agentData?.map((item) => ({
                        label: item.name,
                        value: item.agent_id,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mb-3 fv-plugins-icon-container">
            <div className="mb-3 fv-plugins-icon-container">
              <Dropdown
                name="hpa_config_enabled"
                control={control}
                label="HPA Config"
                options={[
                  { label: "True", value: "true" },
                  { label: "False", value: "false" },
                ]}
                rules={{ required: "This field is required" }}
              />
            </div>
          </div>

          {hpaConfigEnabled && hpaConfigEnabled !== "false" && (
            <div className="row">
              <div className="col-6">
                <div className="mb-3 fv-plugins-icon-container">
                  <NumberField
                    name="minReplicas"
                    control={control}
                    allowNegative={false}
                    label="Min Replicas"
                    rules={{
                      required: "Min replica is required",
                      min: { value: 1, message: "Min replicas must be 1" },
                    }}
                    allowZero={false}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3 fv-plugins-icon-container">
                  <NumberField
                    name="maxReplicas"
                    control={control}
                    label="Max Replicas"
                    allowNegative={false}
                    rules={{
                      required: "Max replica is required",
                      min: { value: 1, message: "Max replicas must be 1" },
                      validate: (value) =>
                        value >= getValues("minReplicas") ||
                        "Max replicas must be greater than or equal to Min replicas",
                    }}
                    allowZero={false}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="mb-3 fv-plugins-icon-container">
                  <NumberField
                    name="targetMemoryUtilizationPercentage"
                    control={control}
                    allowNegative={false}
                    label="Target Memory Utilization %"
                    rules={{
                      required: "Target Memory Utilization is required",
                      min: {
                        value: 1,
                        message: "Target Memory Utilization must be 1",
                      },
                    }}
                    allowZero={false}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="mb-3 fv-plugins-icon-container">
                  <NumberField
                    name="targetCPUUtilizationPercentage"
                    control={control}
                    label="Target Cpu Utilization %"
                    allowNegative={false}
                    rules={{
                      required: "Target CPU utilization is required",
                      min: { value: 1, message: "Replicas must be 1" },
                    }}
                    allowZero={false}
                  />
                </div>
              </div>
            </div>
          )}

          {addDeploymentInfo.isLoading ||
          updateAgentDeploymentInfo.isLoading ||
          updateTeamDeploymentInfo.isLoading ? (
            <PrimaryButton
              handleClick={handleSubmit}
              type="submit"
              disabled={true}
            >
              Submitting
              <span style={{ marginLeft: "5px" }}>
                <Spinner classes={"spinner-border-sm"} />
              </span>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              handleClick={(e) => {
                handleSubmit(e);
              }}
              type="submit"
            >
              Submit
            </PrimaryButton>
          )}
        </form>

        <div className="mt-3">
          {(addDeploymentInfo.isSuccess ||
            updateAgentDeploymentInfo.isSuccess ||
            updateTeamDeploymentInfo.isSuccess) && (
            <SuccessAlert>
              Deployment {addDeploymentInfo.isSuccess ? "Added" : "Updated"}{" "}
              Successfully
            </SuccessAlert>
          )}

          {(addDeploymentInfo.isError ||
            updateAgentDeploymentInfo.isError ||
            updateTeamDeploymentInfo.isError) && (
            <ErrorAlert>Some Error Occurred</ErrorAlert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewDeployment;
