import React, { useState, useEffect, useRef } from "react";
import Editor from "@monaco-editor/react";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import styles from "./textEditor.module.css";

const detectLanguage = (content) => {
  if (!content) return "plaintext";

  const trimmed = content.trim();

  if (/^<\?xml|<\/?[a-zA-Z]+.*?>/.test(trimmed)) return "xml";
  if (trimmed.startsWith("{") || trimmed.startsWith("[")) return "json";
  if (/function\s|\bconst\b|\bvar\b|\bimport\b|\bexport\b/.test(trimmed))
    return "javascript";
  if (/def\s|\bimport\b|\bclass\b|\bself\b/.test(trimmed)) return "python";

  return "plaintext";
};

const validateContent = (language, content) => {
  try {
    if (language === "json") {
      JSON.parse(content);
    } else if (language === "xml") {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(content, "application/xml");
      const parserErrors = xmlDoc.getElementsByTagName("parsererror");

      if (parserErrors.length > 0) {
        return parserErrors[0].textContent;
      }
    }
    return null;
  } catch (error) {
    return error.message;
  }
};

const CodeEditor = ({
  name,
  value,
  placeholder,
  onChange,
  rows = 7,
  ...props
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const normalEditorRef = useRef(null);
  const fullscreenEditorRef = useRef(null);

  const [language, setLanguage] = useState(detectLanguage(value));
  const [error, setError] = useState(null);

  useEffect(() => {
    setLanguage(detectLanguage(value));
  }, [value]);

  const handleEditorChange = (newValue) => {
    setError(validateContent(language, newValue));
    onChange?.({
      target: { name, value: newValue, type: "text" },
    });
  };

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  useEffect(() => {
    const layoutEditor = () => {
      const editor = isFullscreen
        ? fullscreenEditorRef.current
        : normalEditorRef.current;
      if (editor) {
        editor.layout();
      }
    };

    layoutEditor();
  }, [isFullscreen]);

  return (
    <div>
      {!isFullscreen && (
        <div className={styles.normalEditor}>
          <div className="editor-header d-flex justify-content-end">
            <button
              className="btn btn-secondary btn-md"
              onClick={toggleFullscreen}
              title="Fullscreen"
            >
              <FiMaximize />
            </button>
          </div>
          <Editor
            height="150px"
            language={language}
            value={value || placeholder}
            onChange={handleEditorChange}
            options={{
              minimap: { enabled: false },
              autoIndent: "full",
              formatOnPaste: true,
              formatOnType: true,
              wordWrap: "on",
            }}
            {...props}
            theme="vs-dark"
          />
          {error && (
            <div style={{ color: "red", fontSize: "12px", padding: "4px" }}>
              {error}
            </div>
          )}
        </div>
      )}
      {isFullscreen && (
        <div className={styles.fullscreenEditor}>
          <div className="editor-header d-flex justify-content-end">
            <button
              className="btn btn-secondary btn-md mr-5"
              onClick={toggleFullscreen}
              title="Exit Fullscreen"
            >
              <FiMinimize />
            </button>
          </div>
          <Editor
            language={language}
            value={value || placeholder}
            onChange={handleEditorChange}
            options={{
              minimap: { enabled: false },
              autoIndent: "full",
              formatOnPaste: true,
              formatOnType: true,
              wordWrap: "on",
            }}
            {...props}
            theme="vs-dark"
          />
          {error && (
            <div
              style={{
                color: "red",
                fontSize: "12px",
                padding: "4px",
                position: "fixed",
                bottom: 0,
              }}
            >
              {error}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CodeEditor;
