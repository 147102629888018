import React from "react";
import { HookInputField, TextArea } from "../../Components/Input/Input";
import Dropdown from "../../Components/Dropdown/Dropdown";

import { PrimaryButton } from "../../Components/Buttons/Buttons";
import { Spinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";

import { useForm } from "react-hook-form";
import {
  useAddImageMutation,
  useUpdateImageMutation,
} from "../../services/deploymentService";

const AddNewImage = ({ setShowAddNewImage, editImage: newImage }) => {
  console.log("🚀 ~ AddNewImage ~ newImage:", newImage);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      IMAGE_NAME: newImage?.IMAGE_NAME,
      RELEASE_NOTES: newImage?.RELEASE_NOTES,
      ACTIVE: newImage?.ACTIVE || true,
    },
  });

  const [addImage, addImageInfo] = useAddImageMutation();
  const [updateSpace, updateSpaceInfo] = useUpdateImageMutation();

  const handleCancel = () => {
    setShowAddNewImage(false);
  };

  const onSubmit = (data) => {
    const body = {
      IMAGE_NAME: data.IMAGE_NAME,
      RELEASE_NOTES: data.RELEASE_NOTES,
      ACTIVE: data.ACTIVE,
      CREATE_ID: "Factory Admin",
      CREATE_PROCESS: "API",
      UPDATE_ID: "Factory Admin",
      UPDATE_PROCESS: "API",
    };
    if (newImage) {
      updateSpace({ body, image_id: newImage?.AVAILABLE_IMAGE_ID });
      //   setShowAddNewUser(false);
    } else {
      addImage(body);
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasAddUser"
      aria-labelledby="offcanvasAddUserLabel"
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
          {newImage ? "Add Image" : "Edit Image"}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
        <form
          className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework"
          id="addNewUserForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate="novalidate"
        >
          <div className="mb-3 fv-plugins-icon-container">
            <HookInputField
              label="Image Name"
              name="IMAGE_NAME"
              placeholder="Enter image name"
              type="text"
              register={register}
              required={true}
              setValue={setValue}
            />
            {errors.variable_name && (
              <p className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                Image name is required
              </p>
            )}
          </div>

          <div className="mb-3 fv-plugins-icon-container">
            <Dropdown
              name="ACTIVE"
              control={control}
              label="Active"
              options={[
                { label: "True", value: "true" },
                { label: "False", value: "false" },
              ]}
              rules={{ required: "This field is required" }}
            />
          </div>
          <TextArea
            name="RELEASE_NOTES"
            control={control}
            label="Release Notes"
            placeholder="Type release notes..."
            rules={{ required: "Description is required" }}
          />

          {addImageInfo.isLoading ? (
            <PrimaryButton
              handleClick={handleSubmit}
              type="submit"
              disabled={true}
            >
              Submitting
              <span style={{ marginLeft: "5px" }}>
                <Spinner classes={"spinner-border-sm"} />
              </span>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              handleClick={(e) => {
                handleSubmit(e);
              }}
              type="submit"
            >
              Submit
            </PrimaryButton>
          )}

          {/* <SecondaryButton handleClick={handleCancel}>Cancel</SecondaryButton> */}
        </form>

        <div className="mt-3">
          {(addImageInfo.isSuccess || updateSpace.isSuccess) && (
            <SuccessAlert>
              {console.log(addImageInfo.isSuccess, "addSpaceInfo.isSuccess ")}
              Image {addImageInfo.isSuccess ? "Added" : "Updated"} Successfully
            </SuccessAlert>
          )}
          {(addImageInfo.isError || updateSpace.isError) && (
            <ErrorAlert>Some Error Occurred</ErrorAlert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewImage;
